import State from "../state"
import { MfaType, Company, MfaEnrollment, MyProfile } from "app/types/myProfile"
import { NoEnergyReasons, RentedBuilding } from "app/types/rentedBuilding"
import { PossibleContactRoles } from "app/enums/roles"
import { Locales } from "app/i18n/config"
import { getLanguage } from "app/utils/cookies.utils"

export const loading = (state: State): boolean => state.me.loading
export const getProfile = (state: State): MyProfile | undefined => state.me.data
export const getHasOtpAuthenticator = (state: State): boolean => {
  const enrollments = getConfirmedMfaEnrollments(state)
  const enrollment = enrollments.find(
    enrollment => enrollment.type === MfaType.OTP
  )
  if (enrollment) {
    return true
  }
  return false
}

export const getHasOobAuthenticator = (state: State): boolean => {
  const enrollments = getConfirmedMfaEnrollments(state)
  const enrollment = enrollments.find(
    enrollment => enrollment.type === MfaType.SMS
  )
  if (enrollment) {
    return true
  }
  return false
}

export const getOobAuthenticatorId = (state: State): string | undefined => {
  const enrollments = getConfirmedMfaEnrollments(state)
  const enrollment = enrollments.find(
    enrollment => enrollment.type === MfaType.SMS
  )
  if (enrollment) {
    return enrollment.id
  }
}
export const getSmsAuthenticatorPhoneNumber = (
  state: State
): string | undefined => {
  const enrollments = getConfirmedMfaEnrollments(state)
  const enrollment = enrollments.find(
    enrollment => enrollment.type === MfaType.SMS
  )
  if (enrollment) {
    return enrollment.name
  }
}
export const onlyHasOobAuthenticator = (state: State): boolean => {
  const hasOobAuthenticator = getHasOobAuthenticator(state)
  const hasOtpAuthenticator = getHasOtpAuthenticator(state)
  return hasOobAuthenticator && !hasOtpAuthenticator
}

export const getHasBothAuthenticators = (state: State): boolean => {
  const hasOobAuthenticator = getHasOobAuthenticator(state)
  const hasOtpAuthenticator = getHasOtpAuthenticator(state)
  return hasOobAuthenticator && hasOtpAuthenticator
}

export const getConfirmedMfaEnrollments = (state: State): MfaEnrollment[] => {
  const profile = getProfile(state)
  return profile?.mfaEnrollments.filter(e => e.confirmed) ?? []
}

export const errors = (state: State): object => state.me.errors
export const hasMultipleCompanies = (state: State): boolean => {
  const profile = getProfile(state)
  if (profile) {
    return profile.companies.length > 1
  }
  return false
}
export const getRentedBuildings = (
  state: State
): RentedBuilding[] | undefined => state.me.data?.rentedBuildings
export const getSortedRentedBuildings = (
  state: State
): RentedBuilding[] | undefined =>
  state.me.data?.rentedBuildings.sort((a, b) =>
    a.city > b.city ? 1 : b.city > a.city ? -1 : 0
  )

export const getRentedBuildingsOrderedByPreferredBuilding = (
  state: State
): RentedBuilding[] => {
  const rentedBuildings = getRentedBuildings(state) ?? []
  const preferredBuildingId = getPreferredBuildingId(state)
  if (preferredBuildingId) {
    return rentedBuildings.reduce(
      (result: RentedBuilding[], rb: RentedBuilding) => {
        if (rb.id === preferredBuildingId) {
          return [rb, ...result]
        }
        return [...result, rb]
      },
      []
    )
  }
  return rentedBuildings
}
export const getPreferredBuildingId = (state: State): string | undefined =>
  state.me.data?.preferredBuilding
export const getUser = (state: State): MyProfile | undefined => state.me.data
export const getBuildingById =
  (id?: string) =>
  (state: State): RentedBuilding | undefined => {
    const rentedbuildings = state.me.data?.rentedBuildings
    if (rentedbuildings) {
      return rentedbuildings.find(r => r.id === id)
    }
    const adminBuildings = state.adminBuildingSelect.buildings
    if(adminBuildings) {
      const b = adminBuildings.find(r => r.id === id)
      if(b) {
        return {
          city: b.city,
          postalCode: b.postalCode,
          street: b.street,
          houseNo: b.houseNo, 
          country: b.rb_country, 
          id: b.id,
          endDate: b.rb_end_date,
          noEnergyReason: NoEnergyReasons.NANOGRID_READY
        } as RentedBuilding
      }
    }
    return undefined
  }
export const getGroups = (state: State): string[] | undefined => state.me.data?.groups
export const getRolesByRentedBuildingId =
  (id?: string) =>
  (state: State): PossibleContactRoles[] | undefined => {
    const rentedBuildings = state.me.data?.rentedBuildings
    if (rentedBuildings) {
      const rentedBuilding = rentedBuildings.find(r => r.id === id)
      return rentedBuilding?.roles
    }
    return undefined
  }
export const isAdminInRentedBuilding = (state: State): boolean => {
  const rentedBuildings = state.me.data?.rentedBuildings
  if (rentedBuildings) {
    return rentedBuildings?.some(rb =>
      rb.roles.includes(PossibleContactRoles.ADMIN)
    )
  }
  return false
}
export const getAmountOfRentedBuildings = (state: State): number =>
  state.me.data?.rentedBuildings.length || 0
export const getRentedBuildingIds = (state: State): string[] =>
  state.me.data?.rentedBuildings.map(r => r.id) || []
export const hasSingleRentedBuilding = (state: State): boolean =>
  state.me.data?.rentedBuildings?.length === 1
export const getSingleRentedBuilding = (
  state: State
): RentedBuilding | undefined => state.me.data?.rentedBuildings[0]
export const getUserId = (state: State): string | undefined => state.me.data?.id
export const getRentedBuildingsWhereAdmin = (state: State): RentedBuilding[] =>
  !state.me.data
    ? []
    : state.me.data.rentedBuildings.filter(rb =>
        rb.roles.includes(PossibleContactRoles.ADMIN)
      )

export const isAdminOnPreferredBuilding =
  (preferredBuildingId: string | undefined) =>
  (state: State): boolean => {
    if (!state.me.data || !preferredBuildingId) return false
    const preferredBuilding = state.me.data.rentedBuildings.find(
      building => building.id === preferredBuildingId
    )
    if (!preferredBuilding) return false
    return preferredBuilding.roles.includes(PossibleContactRoles.ADMIN)
  }
export const getUserCompanyName = (state: State): string =>
  !state.me.data || !state.me.data.companies[0].name
    ? ""
    : state.me.data.companies[0].name

export const getUserCompany = (state: State): Company | undefined =>
  state.me.data?.companies[0]
export const getFirstName = (state: State): string =>
  state.me.data?.firstName || ""
export const getLastName = (state: State): string =>
  state.me.data?.lastName || ""
export const getFullName = (state: State) => {
  if (!state.me.data) {
    return ""
  }
  const {
    me: {
      data: { firstName, lastName }
    }
  } = state
  return `${firstName} ${lastName}`
}
export const hasBeenOnboarded = (state: State): boolean =>
  state.me.data?.onboardedAt ? true : false

export const hasMonthlyNewsletter = (state: State): boolean =>
  !!state.me.data?.monthlyNewsletter

export const getPreferredBuilding = (
  state: State
): RentedBuilding | undefined => {
  const preferredBuildingId = getPreferredBuildingId(state)
  const preferredBuilding = state.me.data?.rentedBuildings.find(
    r => r.id === preferredBuildingId
  )
  return preferredBuilding
}

export const getPreferredLanguage = (state: State): Locales => {
  return state.me.data?.languagePreference || getLanguage() || Locales.EN_GB
}

export const getShortenedUserRoles = (state: State): string | undefined => {
  const rentedBuildingId = state.buildingSwitcher.active
  const rentedBuildings = state.me.data?.rentedBuildings
  const rentedBuilding = rentedBuildings?.find(r => r.id === rentedBuildingId)
  const roles = rentedBuilding?.roles
  const shortedRoles = roles?.map(role => role.toUpperCase().substring(0, 2))
  return shortedRoles?.join(";")
}

export const getLastPasswordChange = (state: State): string | undefined => {
  return state?.me?.data?.lastPasswordChange
}
