import {
  isValidPhoneNumber,
  parsePhoneNumber
} from "react-phone-number-input/mobile"

import { object, string } from "yup"

export const phone1 = string()
  .required("portal.input.required")
  .test("phone2", "portal.input.required", (val: any) => (val ? true : false))
  .test(
    "phone2",
    "portal.contactPerson.input.phone.error.country",
    function (val: any) {
      if (val?.length < 5) return true
      const phoneNumber = val && parsePhoneNumber(val)
      if (phoneNumber?.countryCallingCode) return true
      return false
    }
  )
  .test("phone1", "portal.contactPerson.input.phone.error", (val: any) =>
    val ? isValidPhoneNumber(val) : false
  )

export type PhoneNumberValue = {
  phoneNumber: string
}

export const validationSchema = object({
  phoneNumber: phone1
})

export const initialValues = {
  phoneNumber: ""
}
