import { object, string } from "yup"

export type EnrollValue = {
  oneTimeCode: string
}

export const validationSchema = object({
  oneTimeCode: string().required("error.required"),
})

export const initialValues = {
  oneTimeCode: ""
}
