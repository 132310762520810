import { AssociationTypes } from "app/api/mfa"
import { Action, Types } from "./authentication.actions"

export type AuthenticationState = {
  token?: string
  loading: boolean
  mfaToken?: string
  oobCode?: string
  type: AssociationTypes
  error?: unknown
}

const initialState: AuthenticationState = {
  loading: false,
  type: AssociationTypes.OTP
}

export default (state = initialState, action: Action): AuthenticationState => {
  switch (action.type) {
    case Types.AUTHENTICATE:
      return {
        ...state,
        loading: true
      }
    case Types.AUTHENTICATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case Types.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token
      }

    case Types.AUTHENTICATE_RESET:
      return {
        mfaToken: undefined,
        loading: false,
        type: AssociationTypes.OTP
      }

    case Types.VERIFY_MFA:
      return {
        ...state,
        loading: true
      }

    case Types.START_MFA:
      return {
        ...state,
        loading: false,
        mfaToken: action.payload.mfaToken
      }

    case Types.SET_MFA_TYPE:
      return {
        ...state,
        type: action.payload.type
      }

    case Types.SET_MFA_TYPE_SUCCESS:
      return {
        ...state,
      }

    case Types.SET_MFA_TYPE_FAIL:
      return {
        ...state,
        error: action.payload.error
      }

    case Types.CHALLENGE_MFA:
      return {
        ...state,
        loading: true,
      }

    case Types.CHALLENGE_MFA_SUCCESS:
      return {
        ...state,
        loading: false,
        oobCode: action.payload.oobCode
      }

    case Types.CHALLENGE_MFA_FAIL:
      return {
        ...state,
        loading: false
      }

    case Types.VERIFY_MFA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }

    case Types.VERIFY_MFA_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token
      }

    default:
      return state
  }
}
