import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./authentication.actions"
import * as selectors from "./authentication.selectors"
import { fire } from "app/redux/appEvents/appEvents.actions"
import * as meSelectors from "./../me/me.selectors"
import { authenticate, challengeMfa, verifyMfaOtp } from "app/api/auth0"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { intl, Locales } from "app/i18n/config"
import { startMfa } from "./authentication.actions"
import { onlyHasOobAuthenticator } from "./../me/me.selectors"
import { AssociationTypes } from "app/api/mfa"

export function* authenticateFlow(
  action: actions.AuthenticateAction
): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )
  const { currentPassword, audience, scope, actionOnSuccess } = action.payload
  try {
    const { email } = yield select(meSelectors.getProfile)
    const response = yield call(authenticate, {
      username: email,
      password: currentPassword,
      audience,
      scope
    })
    const token = response.data.access_token
    yield put(actions.authenticateSuccess(token))
    if (actionOnSuccess) {
      return yield put(actionOnSuccess())
    }
  } catch (e: any) {
    const status = e.response.status
    const error = e.response.data.error
    const mfaToken = e.response.data.mfa_token
    if (status === 403) {
      if (error === "mfa_required") {
        return yield put(startMfa({ mfaToken }))
      }
    }
    yield put(
      fire({
        local: true,
        uniqueIdentifier: "authentication-error",
        eventName: PossibleAppEvents.ERROR,
        props: {
          title: intl[preferredLanguage].formatMessage({
            id: "portal.authentication.error.title"
          }),
          description: intl[preferredLanguage].formatMessage({
            id: "portal.authentication.error.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(actions.authenticateFail(e))
  }
}

export function* startMfaFlow(): SagaIterator {
  const onlyHasOob = yield select(onlyHasOobAuthenticator)
  if (onlyHasOob) {
    return yield put(actions.setMfaType(AssociationTypes.OOB))
  }
}

export function* setMfaTypeFlow(action: actions.SetMfaType): SagaIterator {
  const { type } = action.payload
  if (type === AssociationTypes.OOB) {
    yield put(actions.challengeMfa())
  }
  yield put(actions.setMfaTypeSuccess())
}

export function* challengeMfaFlow(): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )
  try {
    const mfaToken = yield select(selectors.getMfaToken)
    const authenticatorId = yield select(meSelectors.getOobAuthenticatorId)
    const response = yield call(challengeMfa, {
      mfaToken,
      authenticatorId
    })
    const oobCode = response.data.oob_code
    return yield put(actions.challengeMfaSuccess({ oobCode }))
  } catch (e: any) {
    const status = e.response.status
    if (status === 429) {
      yield put(
        fire({
          local: true,
          uniqueIdentifier: "authentication-error",
          eventName: PossibleAppEvents.ERROR,
          props: {
            title: intl[preferredLanguage].formatMessage({
              id: "portal.authentication.mfa.too-many-requests-error.title"
            }),
            description: intl[preferredLanguage].formatMessage({
              id:
                "portal.authentication.mfa.too-many-requests-error.description"
            }),
            variant: "error",
            show: true
          }
        })
      )
    } else {
      yield put(
        fire({
          local: true,
          uniqueIdentifier: "authentication-error",
          eventName: PossibleAppEvents.ERROR,
          props: {
            title: intl[preferredLanguage].formatMessage({
              id: "portal.authentication.mfa.error.title"
            }),
            description: intl[preferredLanguage].formatMessage({
              id: "portal.authentication.mfa.error.description"
            }),
            variant: "error",
            show: true
          }
        })
      )
    }
    return yield put(actions.challengeMfaFail(e))
  }
}

export function* verifyMfaFlow(action: actions.VerifyMfaAction): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )
  try {
    const { oneTimeCode, type, actionOnSuccess } = action.payload
    const mfaToken = yield select(selectors.getMfaToken)
    const oobCode = yield select(selectors.getOobCode)
    const response = yield call(verifyMfaOtp, {
      mfaToken,
      type,
      oobCode,
      oneTimeCode
    })
    const token = response.data.access_token
    yield put(actions.verifyMfaSuccess(token))
    if (actionOnSuccess) {
      return yield put(actionOnSuccess())
    }
  } catch (e: any) {
    yield put(
      fire({
        local: true,
        uniqueIdentifier: "authentication-error",
        eventName: PossibleAppEvents.ERROR,
        props: {
          title: intl[preferredLanguage].formatMessage({
            id: "portal.authentication.mfa.error.title"
          }),
          description: intl[preferredLanguage].formatMessage({
            id: "portal.authentication.mfa.error.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(actions.verifyMfaFail(e))
  }
}

export default function* authenticationSaga(): SagaIterator {
  yield takeLatest(actions.Types.AUTHENTICATE, authenticateFlow)
  yield takeLatest(actions.Types.VERIFY_MFA, verifyMfaFlow)
  yield takeLatest(actions.Types.START_MFA, startMfaFlow)
  yield takeLatest(actions.Types.CHALLENGE_MFA, challengeMfaFlow)
  yield takeLatest(actions.Types.SET_MFA_TYPE, setMfaTypeFlow)
}
