import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ResendButton = styled.button`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.green.contrast};
  background: transparent;
  border: 0;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & strong {
    font-weight: ${theme.fontWeights.bold};
  }
`
