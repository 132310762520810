import { Button, InputElement } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useFormik } from "formik"
import { validationSchema, EnrollValue, initialValues } from "./config"
import { getError } from "app/redux/authorization/authorization.selectors"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { DescriptionContainer } from "../style"
import { ButtonContainer } from "./style"
import AppEventProvider from "app/components/AppEventsProvider"
import { clearHttpError } from "app/redux/httpErrors/httpErrors.actions"
import { actions as wizardActions } from "app/redux/mfaOtpWizard/mfaOtpWizard.actions"
import { enroll } from "app/redux/mfa/mfa.actions"
import { AssociationTypes } from "app/api/mfa"

const Enroll = () => {
  const { translate } = useTranslate()
  const serverError = useSelector(getError)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearHttpError())
  }, [dispatch])

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setSubmitting,
    isValid,
    dirty
  } = useFormik({
    initialValues,
    onSubmit: ({ oneTimeCode }: EnrollValue) => {
      isValid && dispatch(enroll(AssociationTypes.OOB, oneTimeCode))
    },
    validationSchema
  })

  const hasErrors = (name: string) => {
    return errors[name as keyof EnrollValue] &&
      touched[name as keyof EnrollValue]
      ? true
      : false
  }

  const handleOnPrevious = () => {
    return dispatch(wizardActions.decreaseWizard())
  }

  useEffect(() => {
    serverError && setSubmitting(false)
  }, [serverError, setSubmitting])

  return (
    <form onSubmit={handleSubmit}>
      <AppEventProvider local />
      <DescriptionContainer>
        {translate("portal.account.account.mfa.oob.enroll.description")}
      </DescriptionContainer>
      <InputElement
        label={translate("portal.account.account.mfa.oob.step3.input.label")}
        name="oneTimeCode"
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasErrors("oneTimeCode")}
        errorMessage={
          hasErrors("oneTimeCode") && translate(errors.oneTimeCode!)
        }
        dataTestId="portal.account.account.mfa.oob.step3.input.label"
      />
      <ButtonContainer>
        <Button
          onClick={handleOnPrevious}
          type="secondary"
          label={translate("portal.account.account.mfa.oob.step2.previous")}
        />
        <Button
          label={translate("portal.account.account.mfa.oob.step2.button")}
          disabled={!isValid || !dirty}
          submit
        />
      </ButtonContainer>
    </form>
  )
}

export default Enroll
