import { AxiosPromise } from "axios"
import { auth0Api } from "./api"
import { AUTH0_DOMAIN, CLIENT_ID } from "./config"

export enum AssociationTypes {
  OTP = "otp",
  OOB = "oob"
}

export const authenticate = (
  username: string,
  password: string
): AxiosPromise => {
  return auth0Api(`/oauth/token`, {
    method: "POST",
    data: {
      grant_type: "password",
      username,
      password,
      client_id: CLIENT_ID,
      audience: `https://${AUTH0_DOMAIN}/mfa/`,
      scope: "enroll"
    }
  })
}

export const associate = (
  token: string,
  type: AssociationTypes,
  phoneNumber?: string
): AxiosPromise => {
  return auth0Api(`/mfa/associate`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      client_id: CLIENT_ID,
      authenticator_types: [type],
      ...(AssociationTypes.OOB &&
        phoneNumber && { oob_channels: ["sms"], phone_number: phoneNumber })
    }
  })
}

export const enroll = (
  token: string,
  type: AssociationTypes,
  code: string,
  oobCode?: string
): AxiosPromise => {
  return auth0Api(`/oauth/token`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      grant_type: `http://auth0.com/oauth/grant-type/mfa-${type}`,
      client_id: CLIENT_ID,
      audience: `https://${AUTH0_DOMAIN}/mfa/`,
      mfa_token: token,
      ...(AssociationTypes.OTP && { otp: code }),
      ...(AssociationTypes.OOB &&
        oobCode && { oob_code: oobCode, binding_code: code })
    }
  })
}

export const deleteAuthenticatorById = (
  token: string,
  id: string
): AxiosPromise => {
  return auth0Api(`mfa/authenticators/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
