import { Button, Phone } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useFormik } from "formik"
import { validationSchema, PhoneNumberValue, initialValues } from "./config"
import { getError } from "app/redux/authorization/authorization.selectors"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { ButtonContainer, DescriptionContainer } from "../style"
import AppEventProvider from "app/components/AppEventsProvider"
import { clearHttpError } from "app/redux/httpErrors/httpErrors.actions"
import { associate } from "app/redux/mfa/mfa.actions"
import { AssociationTypes } from "app/api/mfa"
import { ResendButton } from "./style"

const PhoneNumber = () => {
  const { translate } = useTranslate()
  const serverError = useSelector(getError)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearHttpError())
  }, [dispatch])

  const {
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    values,
    dirty
  } = useFormik({
    initialValues,
    onSubmit: ({ phoneNumber }: PhoneNumberValue) => {
      isValid &&
        dispatch(associate(AssociationTypes.OOB, phoneNumber))
    },
    validationSchema
  })

  const hasErrors = (name: string) => {
    return errors[name as keyof PhoneNumberValue] &&
      touched[name as keyof PhoneNumberValue]
      ? true
      : false
  }

  const handlePhoneChange = (val: string, name: string) => {
    setFieldTouched(name, true)
    setFieldValue(name, val)
  }

  const handleOnResendClick = () => {
    isValid && dispatch(associate(AssociationTypes.OOB, values.phoneNumber))
  }

  useEffect(() => {
    serverError && setSubmitting(false)
  }, [serverError, setSubmitting])

  return (
    <form onSubmit={handleSubmit}>
      <AppEventProvider local />
      <DescriptionContainer>
        {translate("portal.account.account.mfa.oob.association.description")}
      </DescriptionContainer>
      <Phone
        label={translate("portal.account.account.mfa.oob.step2.input.label")}
        name="phoneNumber"
        onChange={handlePhoneChange}
        onBlur={handleBlur}
        error={hasErrors("phoneNumber")}
        errorMessage={
          hasErrors("phoneNumber") && translate(errors.phoneNumber!)
        }
      />
      <ResendButton type="button" disabled={!isValid || !dirty} onClick={handleOnResendClick}>
        {translate("portal.account.account.mfa.oob.resend")}
      </ResendButton>
      <ButtonContainer>
        <Button
          label={translate("portal.account.account.mfa.oob.step2.button")}
          disabled={!isValid || !dirty}
          submit
        />
      </ButtonContainer>
    </form>
  )
}

export default PhoneNumber
