import { Action, Types } from "./mfa.actions"

export type MfaState = {
  barcodeUri?: string
  oobCode?: string
}

const initialState: MfaState = {
  barcodeUri: undefined,
  oobCode: undefined
}

export default (state = initialState, action: Action): MfaState => {
  switch (action.type) {
    case Types.MFA_ASSOCIATE:
      return {
        ...state
      }
    case Types.MFA_ASSOCIATE_SUCCESS:
      return {
        ...state,
        barcodeUri: action.payload.barcodeUri,
        oobCode: action.payload.oobCode
      }
    case Types.MFA_ASSOCIATE_FAIL:
      return {
        ...state
      }

    case Types.MFA_ENROLL:
      return {
        ...state
      }
    case Types.MFA_ENROLL_SUCCESS:
      return {
        ...state
      }
    case Types.MFA_ENROLL_FAIL:
      return {
        ...state
      }

    case Types.MFA_DELETE:
      return {
        ...state
      }
    case Types.MFA_DELETE_SUCCESS:
      return {
        ...state
      }
    case Types.MFA_DELETE_FAIL:
      return {
        ...state
      }

    default:
      return state
  }
}
