import { useDispatch, useSelector } from "react-redux"
import { InputLabel, ProfileCard, BaseCard } from "@paudigital/wdp-components"
import { getUser as getProfile } from "app/redux/me/me.selectors"
import { useTranslate } from "hooks/translate"
import Section from "app/components/Section"
import { RentedBuilding } from "app/types/rentedBuilding"
import { AccountSection, StyledContactCard, StyledPasswordCard } from "./style"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { ProfileActions } from "app/enums/profile"
import { formatPhoneNumberIntl } from "react-phone-number-input"
import MfaOtpCard from "./MfaOtpCard"
// import MfaOobCard from "./MfaOobCard"

const Account = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const profile = useSelector(getProfile)
  const {
    firstName,
    lastName,
    profilePictureUrl,
    email,
    telephone,
    mobilephone
  } = profile!

  const handleEditProfile = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.PROFILE_EDIT,
        uniqueIdentifier: ProfileActions.PROFILE_EDIT
      })
    )
  }

  const handleEditContact = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.CONTACT_EDIT,
        uniqueIdentifier: ProfileActions.CONTACT_EDIT
      })
    )
  }

  const handleEditPassword = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.PASSWORD_EDIT,
        uniqueIdentifier: ProfileActions.PASSWORD_EDIT
      })
    )
  }

  const sumRoles = (total: number, b: RentedBuilding) => {
    return total + b.roles.length
  }
  // TODO - this logic seems broken - seem to be other way around (amountBuildings - amountRoles)
  const roles = translate("portal.account.account.profile.edit.roles", {
    amountBuildings: profile?.rentedBuildings.reduce(sumRoles, 0),
    amountRoles: profile?.rentedBuildings.length
  })

  return (
    <Section title={translate("portal.account.account.title")}>
      <>
        <AccountSection>
          <InputLabel
            label={translate("portal.account.account.profile.subtitle")}
          />
          <ProfileCard
            active
            avatar={profilePictureUrl}
            functionDescription={profile?.function}
            roles={roles}
            label={`${firstName} ${lastName}`}
            onEdit={handleEditProfile}
            translations={{
              edit: translate("portal.account.account.profile.edit.label")
            }}
            dataTestId="profile"
          />
        </AccountSection>
        <AccountSection data-testid="contact-details">
          <InputLabel
            label={translate("portal.account.account.details.subtitle")}
          />
          <StyledContactCard
            dataTestId="contact"
            onEdit={handleEditContact}
            active
            data={[
              {
                label: translate("portal.account.account.email.label"),
                value: email
              },
              {
                label: translate("portal.account.account.phone.label"),
                value: formatPhoneNumberIntl(telephone)
              },
              ...(mobilephone
                ? [
                  {
                    label: translate("portal.account.account.phone2.label"),
                    value: formatPhoneNumberIntl(mobilephone)
                  }
                ]
                : [])
            ]}
            translations={{
              edit: translate("portal.account.account.details.edit.label")
            }}
          />
        </AccountSection>
        <AccountSection>
          <InputLabel
            label={translate("portal.account.account.password.subtitle")}
          />
          <StyledPasswordCard
            char="*"
            maskLength={6}
            label={translate("portal.account.account.password.password.label")}
            translations={{
              edit: translate("portal.account.account.password.edit.label")
            }}
            onEdit={handleEditPassword}
            dataTestId="password"
          />
          <BaseCard />
        </AccountSection>
        <AccountSection>
          <InputLabel
            label={translate("portal.account.account.mfa.subtitle")}
          />
          <MfaOtpCard />
          {/*  INFO: Disabled because feature is not payed for on auth0 */}
          {/* <MfaOobCard /> */}
        </AccountSection>
      </>
    </Section>
  )
}

export default Account
