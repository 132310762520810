import { SagaIterator } from "redux-saga"
import { call, put, takeLatest, select } from "redux-saga/effects"
import * as actions from "./mfa.actions"
import * as meSelectors from "./../me/me.selectors"
import * as authenticationActions from "app/redux/authentication/authentication.actions"
import * as meActions from "app/redux/me/me.actions"
import { actions as wizardActions } from "app/redux/mfaOtpWizard/mfaOtpWizard.actions"
import { fire, remove } from "../appEvents/appEvents.actions"
import { actions as mfaOtpWizardActions } from "app/redux/mfaOtpWizard/mfaOtpWizard.actions"
import { intl, Locales } from "app/i18n/config"
import { associate, deleteAuthenticatorById, enroll } from "app/api/mfa"
import QRCode from "qrcode"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { getToken } from "../authentication/authentication.selectors"
import { getOobCode } from "../mfa/mfa.selectors"
import { MfaEnrollment } from "app/types/myProfile"

function* associateFlow(action: actions.MfaAssociateAction): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )
  try {
    const { type, phoneNumber } = action.payload
    const token = yield select(getToken)
    const response = yield call(associate, token, type, phoneNumber)
    const barcodeUri = response.data?.barcode_uri
    const oobCode = response.data?.oob_code
    if (barcodeUri) {
      const result: string = yield call(generateQR, barcodeUri)
      yield put(actions.associateSuccess({ barcodeUri: result }))
    }
    if (oobCode) {
      yield put(actions.associateSuccess({ oobCode }))
    }
    yield put(mfaOtpWizardActions.increaseWizard())
  } catch (e: any) {
    const status = e.response.status
    if (status === 429) {
      yield put(
        fire({
          local: true,
          uniqueIdentifier: "mfa-error",
          eventName: PossibleAppEvents.ERROR,
          props: {
            title: intl[preferredLanguage].formatMessage({
              id: "portal.authentication.mfa.too-many-requests-error.title"
            }),
            description: intl[preferredLanguage].formatMessage({
              id:
                "portal.authentication.mfa.too-many-requests-error.description"
            }),
            variant: "error",
            show: true
          }
        })
      )
    } else {
      yield put(
        fire({
          local: true,
          uniqueIdentifier: "mfa-error",
          eventName: PossibleAppEvents.ERROR,
          props: {
            title: intl[preferredLanguage].formatMessage({
              id: "portal.account.account.mfa.association.error.title"
            }),
            description: intl[preferredLanguage].formatMessage({
              id: "portal.account.account.mfa.association.error.description"
            }),
            variant: "error",
            show: true
          }
        })
      )
    }
    yield put(actions.associateFail())
  }
}

function* enrollFlow(action: actions.MfaEnrollAction): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )

  try {
    yield put(remove("mfa-error"))
    const { code, type } = action.payload
    const token = yield select(getToken)
    const oobCode = yield select(getOobCode)
    yield call(enroll, token, type, code, oobCode)
    yield put(actions.enrollSuccess())
    yield put(remove("mfa-set-up"))
    yield put(
      fire({
        eventName: PossibleAppEvents.SECURITY_SETTINGS_SAVED_POPUP,
        uniqueIdentifier: "security-settings-saved"
      })
    )
    yield put(meActions.getMe())
    yield put(authenticationActions.authenticateReset())
    yield put(wizardActions.clearWizard())
  } catch (e: any) {
    yield put(
      fire({
        local: true,
        uniqueIdentifier: "mfa-error",
        eventName: PossibleAppEvents.ERROR,
        props: {
          title: intl[preferredLanguage].formatMessage({
            id: "portal.account.account.mfa.enroll.error.title"
          }),
          description: intl[preferredLanguage].formatMessage({
            id: "portal.account.account.mfa.enroll.error.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(actions.enrollFail())
  }
}

function* deleteFlow(action: actions.MfaDeleteAction): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )
  try {
    const { type } = action.payload
    yield put(remove("mfa-error"))
    const token = yield select(getToken)
    const enrollments = yield select(meSelectors.getConfirmedMfaEnrollments)
    const { id } = enrollments.find(
      (enrollment: MfaEnrollment) => enrollment.type === type
    )
    yield call(deleteAuthenticatorById, token, id)
    yield put(actions.deleteMfaSuccess())
    yield put(remove("mfa-delete"))
    yield put(meActions.getMe())
    yield put(authenticationActions.authenticateReset())
  } catch (e: any) {
    yield put(
      fire({
        local: true,
        uniqueIdentifier: "mfa-error",
        eventName: PossibleAppEvents.ERROR,
        props: {
          title: intl[preferredLanguage].formatMessage({
            id: "portal.account.account.mfa.enroll.error.title"
          }),
          description: intl[preferredLanguage].formatMessage({
            id: "portal.account.account.mfa.enroll.error.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(actions.deleteMfaFail())
  }
}
async function generateQR(uri: string) {
  return await QRCode.toDataURL(uri, { type: "image/jpeg" })
}

export default function* mfaSaga(): SagaIterator {
  yield takeLatest(actions.Types.MFA_ASSOCIATE, associateFlow)
  yield takeLatest(actions.Types.MFA_ENROLL, enrollFlow)
  yield takeLatest(actions.Types.MFA_DELETE, deleteFlow)
}
