import { AppEventComponentProps } from "app/redux/appEvents/types"

export enum PossibleAppEvents {
  ACCOUNT_CREATED,
  ACCOUNT_DELETED,
  ERROR,
  DELETE_CONTACT_PERSON,
  CLOSE_REPORT,
  CREATE_TICKET,
  SHOW_EXPERT,
  TICKET_CREATED,
  BUILDINGSWITCHER,
  REPORT_DETAIL,
  TICKET_COMMUNICATION,
  TICKET_FEEDBACK,
  CREATE_DOCUMENT,
  ENERGY_DATA_INCOMPLETE_NOTIFICATION,
  INVOICE_DETAIL,
  DOCUMENT_CREATED,
  PASSWORD_EDIT,
  PASSWORD_UPDATED,
  CONTACT_EDIT,
  PROFILE_EDIT,
  INFO_UPDATED,
  ADD_COLLEAGUE,
  CUSTOM_PERIOD,
  DOWNLOAD_TICKETS,
  DOWNLOAD_INVOICES,
  MFA_SET_UP_OTP,
  SECURITY_SETTINGS_SAVED_POPUP,
  CSV_ENERGY,
  MFA_DELETE_OTP_START,
  MFA_DELETE_OTP,
  MFA_SET_UP_OOB,
  MFA_DELETE_OOB_START,
  MFA_DELETE_OOB,
  RESEND_INVITE_SUCCESS,
  CANCEL_TICKET,
  TICKET_CANCELLED,
  DOCUMENT_LOADING
}

export type AppEventMapping = {
  event: PossibleAppEvents
  component: (props: AppEventComponentProps) => JSX.Element
}
